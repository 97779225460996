<template>
    <div class="trailer">
        <my-header @screening="screening" />
        <my-screen :screenObj="screenObj" :inputList='inputList' @onSure="onSure" ref="child" />
        <!-- list列表 -->
        <!-- 列表 -->
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <div class="itemList">
                <van-list v-model="loading" :finished="finished"  @load="onLoad1" >
                    <video-item v-for="item in list" :key="item.id" :item="item" @goDetails="goDetails(item)"></video-item>
                </van-list>
                <no-more v-show="showNoMore && !loading"/>
            </div>
        </van-pull-refresh>
        <!-- 暂无数据 -->
        <no-data v-if="showData"/>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
    export default {
    mixins: [mixinPage],
    data() {
        return {
            api: 'common/base/match/match-notice',
            screenObj: {
                showPop: false,
            },
            inputList: [
                { field: 'matchNameLike', label:'赛事名称', placeholder: '请输入赛事名称', key:'', },
            ],
            model: {}
        }
    },
    methods: {
        afterLoadList(list) {
            list.map(ele=>{
                let picList = []
                let picOne = ''
                if(ele.picUrl){
                    picList = ele.picUrl.split(',');
                    picOne = picList[0]
                }
                this.$set(ele, 'picOne', picOne)
                this.$set(ele, 'picList', picList)
            })
            return list
        },
        init() {
            this.initList()
        },
        onSure(data) {
            this.model = {...this.model, ...data}
            this.init()
            this.$set(this.screenObj, 'showPop', false)
        },
        screening() {
            this.model = {}
            this.$refs.child.setValue(this.model)
            this.$set(this.screenObj, 'showPop', true)
        },
        goDetails(item) {
            this.$router.push({
                path: '/trailer/details',
                query: {
                    id: item.id
                }
            })
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .trailer{
        .itemList{
            margin-top: 50px;
            padding: 0 20px;
        }
    }
</style>